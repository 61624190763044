import React from "react"
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

const Footer = ({ siteTitle }) => (
    <footer>
        <div className="boxed-container">
          <div className="footer-container">
            <div className="copyright">
              ©{new Date().getFullYear()} Kevin Young. All rights reserved.<br />
              <a href="mailto:kevin@kevinyoung.com">kevin@kevinyoung.com</a><br />
            </div>
            <div className="social">
              <a href="https://instagram.com/kevin_young_roadthink" target="_blank"><FaInstagram /></a>
              <a href="https://ca.linkedin.com/in/kevin-young-46356613" target="_blank"><FaLinkedinIn /></a>
            </div>
          </div>
        </div>
    </footer>
)

export default Footer
