import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="boxed-container">
      <div className="logo">
        <h1>
          <Link to="/" >
            {siteTitle}<br />
            <span>Writer / Musician</span>
          </Link>
        </h1>
      </div>
      <div className="boxed-container">
        <nav className="navigation">
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/writing">Writing</Link></li>
            <li><Link to="/music">Music</Link></li>
            <li><Link to="/galleries">Galleries</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
