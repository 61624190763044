import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Kevin Young: Contact" />
    <section>
      <div className="boxed-container">
        <div className="text-block">
          <p>For questions and information related to writing services, feel free to email me at <a href="mailto:kevin@kevinyoung.com">kevin@kevinyoung.com</a>, or use the conveniently located form below and I will respond promptly. For ongoing assignments, project specific writing samples, or to upload/download related assets, please proceed to my digital client lounge and login.</p>
          <p><br /><strong>Rates:</strong> Because every assignment is different and each client’s needs vary, I prefer to provide customized estimates on a per project basis.</p>
          <p>For enquiries about booking MOIST and related professional enquiries please contact <a href="mailto:graeme@kharma.ca">graeme@kharma.ca</a> or The Feldman Agency.</p>
          <p>For music and gear related questions, i.e ‘You know that sound you use on Ophelia - the one that makes animals scream - How did you come up with it? What’s making it? And how do I make it stop?’, or just to geek out about gear or say howdy, please reach out to me at kevin@roadthink.com.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact